import React, { useRef, useState } from "react"
import Slider from "react-slick";
import { Link, useStaticQuery, graphql } from "gatsby"

import view from "./featured.module.scss"

import { configurableSlug } from '../../Modules/configurableVehicleInfo'

import unavailable from '../../images/rv-not-available.png'

import 'lazysizes'

const staticQuery = graphql`
  query  {
    allInventoryJson {
      nodes {
        VIN
        Pricing {
          Cost
          ExtraPrice1
          ExtraPrice2
          List
          ExtraPrice3
          Special
        }
        VehicleInfo {
          VIN
          IsNew
          BodyType
          Make
          Model
          Year
          VehicleStatus
          InStockDate
        }
        ListOfPhotos {
          Order
          PhotoUrl
        }
        Banner {
          BannerContent {
          bgColor
          placement
          text
          textColor
          }
        }
      }
    }
  }
`

export default function FeaturedComponent(props) {
  const { allInventoryJson } = useStaticQuery(staticQuery)
  const vehicles = allInventoryJson.nodes

  const [slide, setSlide] = useState(1)
  const [failed, setFailed] = useState([])
  const slider = useRef(null)
  const { unavailableImg } = props
  const featured = props.featured
  const theSlug = props.theSlug
  const title = props.data.Title

  const handleFallback = (err, idx) => {
    failed.push(idx)
    setFailed([...failed])

    err.target.src = unavailableImg;
  }

  function msrpClass(amount) {
    let theClass = "sr__costs"
    if (amount == 0) {
      theClass = theClass + " msrp__hide"
    }
    return theClass
  }

  function formatPrice(price, defaultPrice) {
    let thePrice = (defaultPrice != undefined && price == 0
      ? defaultPrice
      : '$' + price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
    return thePrice
  }

  const renderFeatured = ({ VehicleInfo, ListOfPhotos, Pricing }, vin, idx) => {
    const { VIN, BodyType, Year, Make, Model, IsNew } = VehicleInfo
      return (
      <Link to={configurableSlug(theSlug,VehicleInfo)}>
      <div className={view["featured-item"]}>
        <div className={view["featured-img"]}>
          <img alt={`${Year} ${(Make == "NONE" ? " " : Make)} ${Model}`} data-src={ListOfPhotos[0] ? ListOfPhotos[0].PhotoUrl : unavailableImg}
            onError={(err) => handleFallback(err, idx)} className={"lazyload"} />
        </div>
        <div className={view["item-content"]}>
        <div className={view["item-price"]}>
        <h3 className={view["item-title-year"]}>{`${Year}`}</h3>
        <h3 className={view["item-title"]}>
          {(Make === "NONE" ? " " : Make)} <span>{Model}</span>
        </h3>
 
          <h3 className={view["sale-price"]}>
            {`${formatPrice(Pricing.ExtraPrice1, "Call for Price")}`}
          </h3>
        </div>

        <div className={view["button-cont"]}>
          <div className={view["action-btn"]}>
              DETAILS
          </div>
         </div>
        </div>	      
       </div>
      </Link>	      
    )
  }

  const isFeaturedVehicle = ({ vehicleVIN }, idx) => {
    const index = vehicles.findIndex((vehicle) => vehicle.VIN === vehicleVIN && vehicle.VehicleInfo.VehicleStatus != "_hidden")
    if (index > -1) {
      return renderFeatured(vehicles[index], vehicleVIN, idx)
    }
  }

  let featureRenders = []
  featured.map((element, idx) => (
      featureRenders[idx] = isFeaturedVehicle(element, idx)
  ))
  let featuredCount = featureRenders.filter(function(x){ return x !== undefined}).length

  const isNotFeaturedVehicle = ( vehicleVIN, i, idx) => {
    if(featuredExists(vehicleVIN)) {
      return false
    }
    else {
      return renderFeatured(oldest[i], vehicleVIN, idx)
    }
  }

  const isNotHidden = (vehicleVIN) => {
    const index = vehicles.findIndex(
      (vehicle) =>
        vehicle.VIN === vehicleVIN &&
        vehicle.VehicleInfo.VehicleStatus != "_hidden"
    );
    return (index > -1)
  }

  function featuredExists(vehicleVIN) {
    return featured.some(function(el) {
      return el.vehicleVIN === vehicleVIN;
    }); 
  }
  const filteredVehicles = () => {
    var results =  vehicles.filter(function(x){ return x.VehicleInfo.IsNew === true && isNotHidden(x.VehicleInfo.VIN)})
    if (results.length === 0) {
      results =  vehicles.filter(function(x){ return isNotHidden(x.VehicleInfo.VIN)})
    }
    return results
  }
  
  
    const oldest = filteredVehicles().sort(function(a,b){return new Date(a.VehicleInfo.InStockDate) - new Date(b.VehicleInfo.InStockDate);});  const fillerVehicles = []
  var featureIdx = featureRenders.filter(function(x){ return x !== undefined}).length
  if(oldest.length > 0  ){
    for(var i = 0; featureIdx + fillerVehicles.length < 4 && oldest.length > i; i++) {
        var returnedVal = isNotFeaturedVehicle(oldest[i].VehicleInfo.VIN, i, (featureIdx + i))
        if(typeof returnedVal === "object") {
          fillerVehicles.push(oldest[i])
          featureRenders[featureRenders.length] = returnedVal
        }
    }
  }
  featuredCount = featureRenders.filter(function(x){ return x !== undefined}).length

  const settings = {
    arrows: false,
    infinite: featuredCount > 1,
    autoplay: false,
    dots: true,
    slidesToShow: Math.min(featuredCount,4),
    slidesToScroll: 1,
    afterChange: i => setSlide(i),
    customPaging: i => (
      <div className="dotContainer"><div className={`
        ${view["nissan-dots"]} 
        ${slide == i && view["nissan-active"]}`}/></div>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(featured.length, 3),
          slidesToScroll: Math.min(featured.length, 3),
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(featured.length, 2),
          slidesToScroll: Math.min(featured.length, 2),
        }
      },
      {
        breakpoint: 518,
        settings: {
          slidesToShow: Math.min(featured.length, 1),
          slidesToScroll: Math.min(featured.length, 1),
        }
      }
    ]
  }

  return featuredCount > 0 ?
    (
      <div id="featured" className={view["featured-sect"]}>
        <div className={view["featured-head"]}>
          {title}
        </div>

        <div className={view["slider-wrap"]}>
          <Slider {...settings} ref={slider}>
            {
              featureRenders.filter(function(x){ return x !== undefined}).map((element, idx) => (
                <div key={idx}>
                  { element }
                </div>
              )
            )}
          </Slider>

          <div className={`${view["arrow-wrap"]} ${view["arrow-prev"]}`}>
            <p className={view["arrow-btn"]} onClick={() => slider.current.slickPrev()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 24" fill="none">
                <path d="M12.373 23.5701C12.5292 23.4114 12.6168 23.1977 12.6168 22.9751C12.6168 22.7524 12.5292 22.5387 12.373 22.3801L1.991 11.9171L12.373 1.43605C12.5292 1.27741 12.6168 1.0637 12.6168 0.841053C12.6168 0.618407 12.5292 0.404692 12.373 0.246052C12.296 0.168107 12.2043 0.106222 12.1032 0.0639853C12.0021 0.0217486 11.8936 0 11.784 0C11.6744 0 11.566 0.0217486 11.4648 0.0639853C11.3637 0.106222 11.272 0.168107 11.195 0.246052L0.243999 11.3031C0.0809995 11.4671 0 11.6721 0 11.8971C0.00132829 12.1195 0.0887819 12.3327 0.243999 12.4921L11.194 23.5501C11.2685 23.6317 11.3588 23.6974 11.4595 23.7429C11.5602 23.7884 11.6692 23.8128 11.7797 23.8147C11.8902 23.8165 11.9999 23.7958 12.1021 23.7537C12.2043 23.7117 12.2968 23.6492 12.374 23.5701H12.373Z" fill="white" />
              </svg>
            </p>
          </div>

          <div className={`${view["arrow-wrap"]} ${view["arrow-next"]}`}>
            <p className={view["arrow-btn"]} onClick={() => slider.current.slickNext()}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 24" fill="none">
                <path d="M0.244046 23.3157C0.0877797 23.1593 0 22.9473 0 22.7262C0 22.5051 0.0877797 22.2931 0.244046 22.1367L10.626 11.7757L0.244046 1.39373C0.100408 1.23506 0.023264 1.02725 0.0285778 0.813293C0.0338916 0.599336 0.121256 0.39561 0.272593 0.244273C0.423931 0.0929357 0.627656 0.00557115 0.841614 0.000257364C1.05557 -0.00505643 1.26338 0.0720871 1.42205 0.215725L12.374 11.1657C12.4522 11.2426 12.514 11.3345 12.5558 11.4359C12.5975 11.5373 12.6184 11.6461 12.617 11.7557C12.6157 11.9762 12.5285 12.1874 12.374 12.3447L1.42205 23.2947C1.34724 23.3755 1.25688 23.4402 1.15639 23.4851C1.0559 23.5301 0.947364 23.5542 0.837313 23.556C0.727262 23.5579 0.61797 23.5375 0.516015 23.496C0.41406 23.4545 0.321548 23.3929 0.244046 23.3147V23.3157Z" fill="white" />
              </svg>
            </p>
          </div>
        </div>

      </div>
    ) : null
}

FeaturedComponent.defaultProps = {
  unavailableImg: unavailable
}
